.edm-live {
	width: 100%;
	height: 35rem;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.edm-live .bg-edm {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
}

.edm-live .bg-edm .edm-top-bg {
	background-color: #F7F7FE;
	width: 100%;
	height: 17.5rem;
}

.edm-live .bg-edm .edm-bottom-bg {
	background-color: #1F7D93;
	width: 100%;
	height: 17.5rem;
	background-image: url('../img//edmLive//img-d.png');
	background-position: center;
	background-repeat: no-repeat;
	background-size: 100%;
}

.edm-live .view-app {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: start;
	z-index: 2;
}

.edm-live .view-app img {
	width: 75%;
	margin-left: 1rem;
}

.edm-live .description {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: start;
	padding: 3rem 4rem;
	z-index: 2;
	margin-top: 3.8rem;
}

.edm-live .description .logo {
	width: 40%;
	margin-top: 2rem;
}

.edm-live .description p {
	font-family: Montserrat;
	font-size: 1rem !important;
	color: #606060;
}

.edm-live .description p span {
	font-weight: 600;
}

.edm-live .description .title {
	padding-top: 0.1rem;
	padding-bottom: 1rem;
}

.edm-live .description button {
	background-color: #ff214f;
	margin-top: 3.5rem;
	height: 25px;
	padding: 1.2rem 1.8rem;
	line-height: 0;
}

.edm-live .description button p {
	font-weight: 600;
	font-size: 0.8rem !important;
	font-size: 1rem;
	color: #fff;
	line-height: 0;
}

.edm-live .description button span {
	font-weight: 900;
}

.edm-live .description .download-edm-live {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-top: 2rem;
}

.edm-live .description .download-edm-live img {
	width: 28%;
	margin-right: 0.5rem;
	cursor: pointer;
}

.edm-live .description .download-edm-live p {
	font-size: 0.9rem !important;
	color: #fff;
}

@media (max-width: 992px) {
	.edm-live .description {
		padding: 2rem 0rem;
		margin-top: 3.8rem;
	}

	.edm-live .view-app img {
		width: 100%;
		margin-left: 2rem;
	}
}

@media (max-width: 768px) {
	.edm-live {
		width: 100%;
		height: 47rem;
	}

	.edm-live .bg-edm .edm-top-bg {
		height: 23.5rem;
	}

	.edm-live .bg-edm .edm-bottom-bg {
		height: 23.5rem;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
	}

	.edm-live .view-app img {
		width: 80%;
		margin-left: 0rem;
	}

	.edm-live .description {
		padding: 0rem 0rem;
		margin-top: 1rem;
	}

	.edm-live .description .text-description {
		margin-top: 2rem;
		color: #000;
	}

	.edm-live .view-app {
		align-items: center;
	}

	.edm-live .description button {
		margin-top: 1.5rem;
	}
}